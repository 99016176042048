let cache = require("../../gatsby-node/defaultCache/defaultCache");


module.exports.has = (key) => {
    return Boolean(cache[key]);
};

module.exports.get = (key) => {
    return cache[key];
};

module.exports.set = (key, value) => {
    cache[key] = value;
};

module.exports.getCacheObject = () => cache;
module.exports.setCacheObject = (storedCache) => cache = storedCache;