import React, { useState, useEffect } from "react";
import { getMaintenance, getInfoBar, getLeadMaintenance } from "../api/api";

const defaultContext = { infoBar: false, maintenance: false };
let cache = null;

export const infoContext = React.createContext(defaultContext);

const Provider = props => {
  const [content, setContent] = useState(defaultContext);

  useEffect(() => {
    (async () => {
      const [maintenance, infoBar, lead] = await Promise.all([await getMaintenance(), await getInfoBar(), await getLeadMaintenance()]);

      setContent({
        infoBar,
        maintenance,
        leadMaintenance: lead,
      });
    })();
  }, []);

  return <infoContext.Provider value={content}>{props.children}</infoContext.Provider>;
};

export default ({ element }) => <Provider>{element}</Provider>;
