/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import "./src/styles/bootstrap.min.css";
import "./src/assets/css/front.css"
import "./src/assets/css/font-face.css"

import Provider from "./src/context/infoProvider";
// exports.wrapRootElement = Provider;


let botxAvailable = false;
let botxTimeout = null;

const startBotx = () => {

    const botxScript = document.createElement('script');
    botxScript.setAttribute('src', "https://cdn.botx.cloud/messenger/botx.min.js?id=3082");
    document.head.appendChild(botxScript);
}

const tryStartBotx = () => {
    clearTimeout(botxTimeout);
    if (
        typeof (window) !== "undefined" && window.location.pathname.substring(0, 8) === '/kontakt'
    ) {
        botxTimeout = setTimeout(startBotx, 60 * 1000);
    }
}


export const onClientEntry = () => {
    window.addEventListener('CookiebotOnAccept', function (e) {
        if (window.Cookiebot.consent.marketing) {
            botxAvailable = true;
            tryStartBotx();
        }
    });
}


export const onRouteUpdate = ({location, prevLocation}) => {
    if (botxAvailable) {
        tryStartBotx();
    }
}

export const wrapRootElement = Provider;

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
  }) => {
    window.history.scrollRestoration = 'manual';

    return location.href.indexOf("#") > -1 ? true : false;
  };