module.exports = (promise, time) => {
  let timeout = new Promise((resolve, reject) => {
    let id = setTimeout(() => {
      clearTimeout(id);
      reject(`Timed out in ${time} ms.`);
    }, time);
  });

  return Promise.race([promise, timeout]);
};
